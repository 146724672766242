import React, { useEffect } from 'react';
import './StudentData.css'; 

const StudentData = ({ student }) => {
  useEffect(() => {
  }, [student]);

  return (
    <>
      
    </>
  );
};

export default StudentData;
