
import './App.css';
import Form from './Form/Form';
function App() {
  return (
    <>
    <Form />
    </>
  );
}

export default App;
